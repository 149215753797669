'use client';

import { DisclaimerComponent, RainbowKitProvider, type Theme, darkTheme as rainbowDarkTheme, lightTheme as rainbowLightTheme } from '@rainbow-me/rainbowkit';
import { useIsMounted } from '@sushiswap/hooks';
import { useTheme } from 'next-themes';
import { type FC, type ReactNode, useMemo } from 'react';
import { WagmiSentry } from 'src/lib/wagmi/components/wagmi-sentry';
import { WagmiStoreVersionCheck } from 'src/lib/wagmi/components/wagmi-store-version-check';
import { getWagmiConfig, getWagmiInitialState } from 'src/lib/wagmi/config';
import { WagmiProvider as _WagmiProvider } from 'wagmi';
const darkTheme: Theme = {
  ...rainbowDarkTheme({
    borderRadius: 'medium',
    overlayBlur: 'small'
  }),
  colors: {
    ...rainbowDarkTheme().colors,
    modalBackground: '#1e293b',
    modalBackdrop: '#00000019',
    modalBorder: '#00000000'
  },
  fonts: {
    body: 'var(--font-sans)'
  }
};
const lightTheme: Theme = {
  ...rainbowLightTheme({
    borderRadius: 'medium',
    overlayBlur: 'small'
  }),
  colors: {
    ...rainbowLightTheme().colors,
    modalBackground: '#ffffff',
    modalBackdrop: '#00000019',
    modalBorder: '#00000000'
  },
  fonts: {
    body: 'var(--font-sans)'
  }
};
const Disclaimer: DisclaimerComponent = ({
  Text,
  Link
}) => <Text data-sentry-element="Text" data-sentry-component="Disclaimer" data-sentry-source-file="wagmi-provider.tsx">
    By connecting your wallet, you agree to Sushi Labs{"' "}
    <Link href="/legal/terms-of-service" data-sentry-element="Link" data-sentry-source-file="wagmi-provider.tsx">Terms of Service</Link> and{' '}
    <Link href="/legal/privacy-policy" data-sentry-element="Link" data-sentry-source-file="wagmi-provider.tsx">Privacy Policy</Link>
  </Text>;
export const WagmiProvider: FC<{
  children: ReactNode;
  cookie?: string | null;
}> = ({
  children,
  cookie
}) => {
  const initialState = getWagmiInitialState(cookie);
  const isMounted = useIsMounted();
  const {
    resolvedTheme
  } = useTheme();
  const rainbowKitTheme = useMemo(() => {
    if (isMounted && resolvedTheme === 'dark') {
      return darkTheme;
    }
    return lightTheme;
  }, [resolvedTheme, isMounted]);
  return <_WagmiProvider config={getWagmiConfig()} initialState={initialState} data-sentry-element="_WagmiProvider" data-sentry-component="WagmiProvider" data-sentry-source-file="wagmi-provider.tsx">
      <div className="h-full w-full [&>div]:h-full">
        <RainbowKitProvider modalSize="compact" theme={rainbowKitTheme} appInfo={{
        disclaimer: Disclaimer
      }} data-sentry-element="RainbowKitProvider" data-sentry-source-file="wagmi-provider.tsx">
          <WagmiSentry data-sentry-element="WagmiSentry" data-sentry-source-file="wagmi-provider.tsx">
            <WagmiStoreVersionCheck data-sentry-element="WagmiStoreVersionCheck" data-sentry-source-file="wagmi-provider.tsx">{children}</WagmiStoreVersionCheck>
          </WagmiSentry>
        </RainbowKitProvider>
      </div>
    </_WagmiProvider>;
};